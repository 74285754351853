import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal, useModal, Button, Text } from "@nextui-org/react";
import CountryWise from "./CountryWise";
import Uniquecountrywise from "./Uniquecountrywise";
import { userStore } from "../../../../store/userStore";
import authQueries from "../../../../queries/authQueries";


const CountrywiseReportNew =()=>{

    function getCurrentFinancialYearDates() {
        const today = new Date();
        const currentMonth = today.getMonth(); // 0-11 where 0 is January
        const currentYear = today.getFullYear();
        
        let financialYearStart, financialYearEnd;
        
        // If current month is January to March (0-2), we're in the previous year's financial year
        if (currentMonth >= 0 && currentMonth <= 2) {
            financialYearStart = new Date(currentYear - 1, 3, 1); // April 1st of previous year
            financialYearEnd = new Date(currentYear, 2, 31);      // March 31st of current year
        } else {
            financialYearStart = new Date(currentYear, 3, 1);     // April 1st of current year
            financialYearEnd = new Date(currentYear + 1, 2, 31);  // March 31st of next year
        }
    
        function formatDate(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        }
    
        return {
            startDate: formatDate(financialYearStart),
            endDate: formatDate(financialYearEnd)
        };
    }
    
    function formatDate(date) {
        var year = date.getFullYear();
        var month = ('0' + (date.getMonth() + 1)).slice(-2); 
        var day = ('0' + date.getDate()).slice(-2);
        return year + '-' + month + '-' + day;
    }
    
    var financialYearDates = getCurrentFinancialYearDates();

    const [fromDate, setfromDate] = useState(financialYearDates.startDate);
    const [toDate, settoDate] = useState(financialYearDates.endDate);

    const [fromDateapi, setfromDateapi] = useState( financialYearDates.startDate);
    const [toDateapi, settoDateapi] = useState(financialYearDates.endDate);
    const [yearFilter, setYearFilter] = useState('current');
    const user = userStore();
    const role = user?.user?.rid;

    
    const handlefromDate = (event) => {
        const value = event.target.value;
        setfromDate(value);
    };

    const handletoDate = (event) => {
        const value = event.target.value;
        settoDate(value);
    };

    const handleSearch = () => {
        setfromDateapi(fromDate)
        settoDateapi(toDate)
      };
    
    

    return(
        <div>
            <div className="add_clients">			
                    <div className="row">
                            
                        <div className="col-md-12">
                            <hr className="progress-hr" />
                        </div>
                    </div>
                    

                    <div id="accordion" className="add_clients_accordion_form" style={{borderBottom:'none'}}>
                        <div  className="card" id="appointments_wrpr_employee">

                        <div id="appointment_any" className="collapse show" data-parent="#accordion">
                            <div className="card-body">
                                <div className="row ">
                                    <div className="col-md-12">
                                        <div className="table-list-search-wrpr">
                                            <div className="row">
                                            <div className="col-md-2">  
                                            </div>
                                            <div className="col-md-6">
                                                    <div className="table-search-filter-wrpr">
                                                        <div style={{display: "-webkit-inline-box" }} className="table-search-wrpr">
                                                               <label style={{marginRight:7, marginTop:11}}>Financial Year</label>
                                                                <select 
                                                                    style={{minWidth: '100px'}} 
                                                                    value={yearFilter} 
                                                                    onChange={(e) => setYearFilter(e.target.value)}
                                                                    className="form-control"
                                                                    >
                                                                    <option value="previous">Previous Year</option>
                                                                    <option value="current">Current Year</option>
                                                                    <option value="future">Future Year</option>
                                                                </select>
                                                          
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-md-4 text-right">
                                                    <div className="table-search-filter-wrpr">
                                                        <div style={{display: "-webkit-inline-box" }} className="table-search-wrpr">
                                                            <label style={{marginRight:7, minHeight:44, display:'inline-flex', alignItems:'center'}}>From</label>
                                                            <input style={{minWidth: '100px', width:'auto'}} value={fromDate} onChange={handlefromDate}  className="form-control" type="date" placeholder="From" />
                                                            <label style={{marginRight:7, minHeight:44, display:'inline-flex', alignItems:'center'}}>To</label>
                                                            <input style={{minWidth: '100px', width:'auto'}} value={toDate} onChange={handletoDate}  className="form-control" type="date" placeholder="TO" />
                                                            <button style={{background: '#323a51',minHeight:44,}} onClick={handleSearch}  className="btn btn-info">Search</button>
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>                           
                                           
                                        <CountryWise fromDate={fromDateapi} toDate={toDateapi} />
                                       
                                        <Uniquecountrywise yearFilter={yearFilter}  fromDate={fromDateapi} toDate={toDateapi} setfromDateapi={setfromDateapi}  settoDateapi={settoDateapi}/>
                                    

                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
    </div>
    ) 
}
export default CountrywiseReportNew;