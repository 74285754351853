import { decode } from 'html-entities';
import React, { Fragment, useEffect, useRef, useState } from 'react'
import authQueries from '../../../queries/authQueries';
import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldArray, useForm, Controller } from "react-hook-form";
import * as yup from 'yup';
import { userStore } from '../../../store/userStore';
import { message } from 'antd';
import { useApi } from '../../Common/ApiProvider';
import toast from 'react-hot-toast'

const OtherDetails = () =>  {

    const appStore = userStore();
    
    const { setApiDataAndNotify, apiData } = useApi()
    
    const { register, control, handleSubmit, reset, setValue, trigger, setError, formState:{ errors } } = useForm({
        // resolver: yupResolver(validationSchema)
    });

    useEffect(() => {
        console.log(appStore?.appAllData?.additional_informations?.notes)
        setValue('notes', appStore?.appAllData?.additional_informations?.notes)
    }, []);

    const updateData = authQueries.useAddOtherDetailsCreate(
        (res)=>{
            if(res?.success){
                setApiDataAndNotify(new Date())
                toast.success("Successfully updated")
            }
        }
    )

    const onSubmit = (data) => {
        data.applicant_id = appStore.appid;
        updateData.mutateAsync(data)
    }

  return (
    <div>
        <div className="card">
            <div className="card-header">
                <a className="collapsed card-link" data-toggle="collapse" href="#other_details">
                    Additional Information
                </a>
            </div>
            <div id="other_details" className="collapse" data-parent="#accordion">
                <div className="px-10 py-4">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <textarea
                        {...register("notes")}
                        className="bg-white border border-solid border-gray-300 rounded-md w-full h-[150px] p-[10px] text-[12px] focus:border-none outline-none" 
                        placeholder="Additional information"
                        name="notes"
                        />
                        <div className="w-full flex justify-end">
                            <button disabled={updateData.isLoading} type="submit" className="custom-color text-[14px] text-white px-4 py-2 rounded-md mt-4">
                                {updateData.isLoading ? 'Updating...' : 'Update'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

  )
}

export default OtherDetails