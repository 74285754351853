const durationFind = (data) => {
    let durationText = '';
    if (data < 60) {
      durationText = `${data} second${data === 1 ? '' : 's'}`;
    } else if (data < 3600) {
      const minutes = Math.floor(data / 60);
      const seconds = data % 60;
      durationText = `${minutes} minute${minutes === 1 ? '' : 's'} ${seconds} second${seconds === 1 ? '' : 's'}`;
    } else {
      const hours = Math.floor(data / 3600);
      const minutes = Math.floor((data % 3600) / 60);
      const seconds = data % 60;
      durationText = `${hours} hour${hours === 1 ? '' : 's'} ${minutes} minute${minutes === 1 ? '' : 's'} ${seconds} second${seconds === 1 ? '' : 's'}`;
    }
  
    return durationText;
};

export default durationFind;