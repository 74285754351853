import React, { Fragment, useEffect, useState } from 'react'
import moment from 'moment'
import LoaderCommon from '../../../Common/LoaderCommon'
import { Empty } from 'antd'
import { Link } from 'react-router-dom'
import { GanttChartSquare } from 'lucide-react'
import { callLogUrl } from '../../../../Data/CallLogData'
import reportQueries from '../../../../queries/reportQueries'
import { Modal } from '@nextui-org/react'
import durationFind from '../../../Common/DurationFinder'


const CallCenterReportDetails = (props) => {

  const [result, setResult] = useState([])
  const [loader, setLoader] = useState(true)
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
    callCenter.mutateAsync(props)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const callCenter = reportQueries.useCallCenterDurationDetailsReport(
    (res)=>{
      if(res?.success){
        setResult(res)
      }
      setLoader(false)
    }
  )


  return (
    <div>
      <button onClick={handleOpen}>{durationFind(props?.count)}</button>
      <Modal
      scroll
      width="80%"
      open={open}
      onClose={handleClose}
      >
          <div className='bg-white p-10 overflow-auto'>
            {
              loader ?
                <LoaderCommon />
              :
              result?.data?.length > 0 ?
              <Fragment>
                <table className='table-dotted w-full'>
                  <thead>
                      <tr className="table-row call-log">
                          <th className='text-center'>#</th>
                          <th className='text-left'>Applicant Number</th>
                          <th className='text-left'>Refference ID</th>
                          <th>Call Duration</th>
                          <th>Call Status</th>
                          <th>Call Type</th>
                          <th>Call Time</th>
                          <th>Download Recording</th>
                      </tr>
                  </thead>
                  <tbody>
                      {result?.data?.map((item, index) => {
                          return(
                              <tr key={index} className="table-row call-log">
                                  <td className='text-center'>{index + 1}</td>
                                  <td className='text-left'>{item?.applicant_no}</td>
                                  <td className='text-left'>{item.callrefid}</td>
                                  <td>{durationFind(item?.callduration)}</td>
                                  <td>{item?.callstatus}</td>
                                  <td>{item?.calltype}</td>
                                  <td>
                                    {moment(item?.calltime)
                                      .utcOffset("+05:30")
                                      .utc()
                                      .format("DD-MM-YYYY hh:mm:ss A")}
                                  </td>
                                  <td>
                                      <a className="text-[blue]" href={`${callLogUrl}/WebAPI.xbc?streamcallwaveeasy()&callrefid=${item.callrefid}`} target="_blank">Click here to download the recording</a>
                                  </td>
                              </tr>
                          )}
                      )}
                  </tbody>
                </table>
              </Fragment>
              :
              <div className='mt-24 mb-24'>
                <Empty
                description={<div className="text-sm text-blue-700" style={{fontFamily: "var(--primary-font)"}}>No data found!</div>}
                />
              </div>
            }
          </div>
      </Modal>
    </div>
  )
}

export default CallCenterReportDetails
