import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import InputSearch from "../../../Common/InputSearch";
import SelectSearch from "../../../Common/SelectSearch";
import LoaderCommon from "../../../Common/LoaderCommon";
import { GanttChartSquare } from "lucide-react";
import reportQueries from "../../../../queries/reportQueries";
import toast from "react-hot-toast";
import DistributionApplicantDetails from "./CallCenterReportDetails";
import CallCenterReportDetails from "./CallCenterReportDetails";
import durationFind from "../../../Common/DurationFinder";
import { Empty } from "antd";


const CallCenterReport =()=>{

    let sl = 0

    const [result, setResult] = useState([]);
    const [fromDate, setFromDate] = useState(moment().format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
    
    const reportBranch = reportQueries.useCallCenterDurationReport(
        (response) => {
            if(response?.success){
                setResult(response?.data);   
            }
        }
    );

    useEffect(()=>{
        reportBranch.mutateAsync({
            from: fromDate,
            to: toDate
        })
    }, [fromDate, toDate])


    const searchFields = [
        {
            label: "From Date",
            size: 2,
            state: fromDate,
            setState: setFromDate,
            input: InputSearch,
            type: "date",
            name: 'name'
        },
        {
            label: "To Date",
            size: 2,
            state: toDate,
            setState: setToDate,
            input: InputSearch,
            type: "date",
            name: 'name'
        }
    ]

    return(
        <div>
            <div className="breadcrumb-and-otherbuttons">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Call Log Report</li>
                    </ol>
                </nav>
            </div>
            
            <div className="add_clients">
                <div className="flex items-center gap-[4px] mb-4">
                    <i className="followup-icon">
                        <GanttChartSquare size="25" className='text-[#7D6FAB]' />
                    </i>
                    <div style={{display:'flex', flexDirection:'column'}}>
                        <div style={{display:'flex', alignItems:'center', gap:'10px'}}>
                            <span className="text-[16px] font-medium">Call Log Report</span>	
                        </div>
                    </div>
                </div>

                <div id="accordion" className="add_clients_accordion_form mt-[10px]">
                    <div  className="card" id="appointments_wrpr_employee">
                        <div className="card-header">
                            Call Log Report
                        </div>
                        <div id="appointment_any" className="collapse show" data-parent="#accordion">
                            <div className="card-body">

                                <div className="grid grid-cols-12 max-[1389px]:grid-cols-10 max-[1163px]:grid-cols-8 max-[929px]:grid-cols-6 max-[585px]:grid-cols-4 max-[445px]:grid-cols-2 gap-x-6 gap-y-5 !mb-5"> 
                                    {
                                        searchFields?.map((item, index)=>{
                                            if(item?.hidden) return
                                            return(
                                                <div key={index} className="col-span-2 flex flex-col gap-2">
                                                    <item.input item={item} />
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                                {reportBranch.isLoading ? (
                                    <LoaderCommon />
                                ) : (
                                    result?.length > 0 ?
                                    <div className="overflow-x-auto max-h-[600px]">
                                        <table
                                            border={1}
                                            className="table-dotted w-full"
                                        >
                                            <thead className="sticky top-0 distribution">
                                                <tr className="text-center h-[45px] table-row call-log custom-color text-white">
                                                    <th className="text-left">Office</th>
                                                    <th className="text-left">Counselor</th>
                                                    <th>Incoming duration</th>
                                                    <th>Outgoing duration</th>
                                                    <th>Total duration</th>
                                                </tr>
                                            </thead>
                                            <tbody className="ui-sortable">
                                                {result?.map((group, groupIndex) => {
                                                    const userCount = group?.users?.length;
                                                    return group?.users?.map((user, userIndex) => {
                                                        sl += 1
                                                        const evenIndex = sl % 2 === 0;
                                                        return (
                                                            <tr key={`${groupIndex}-${userIndex}`} className={`${evenIndex && 'bg-[#9a9bee]'} table-row call-log`}>
                                                                {userIndex === 0 && (
                                                                    <td
                                                                        className="text-left bg-white"
                                                                        rowSpan={userCount}
                                                                    >
                                                                        {group.office || 'N/A'}
                                                                        <br />
                                                                        {durationFind(group?.total_branch_duration)}
                                                                    </td>
                                                                )}
                                                                <td className="text-left">
                                                                    {user?.name}
                                                                    <br />
                                                                    {user?.phone}
                                                                </td>
                                                                <td>
                                                                    <CallCenterReportDetails
                                                                        type="Incoming"
                                                                        from={fromDate}
                                                                        to={toDate}
                                                                        realname={user?.realname}
                                                                        count={user?.call_duration?.incoming_duration}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <CallCenterReportDetails
                                                                        type="Outgoing"
                                                                        from={fromDate}
                                                                        to={toDate}
                                                                        realname={user?.realname}
                                                                        count={user?.call_duration?.outgoing_duration}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <CallCenterReportDetails
                                                                        type=""
                                                                        from={fromDate}
                                                                        to={toDate}
                                                                        realname={user?.realname}
                                                                        count={user?.call_duration?.total_duration}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        )}
                                                    )}
                                                )}
                                            </tbody>
                                        </table>
                                    </div>:
                                    <div className='mt-24 mb-24'>
                                        <Empty
                                        description={<div className="text-sm text-blue-700" style={{fontFamily: "var(--primary-font)"}}>Counselors not found!</div>}
                                        />
                                    </div>
                                )}
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CallCenterReport;

const findTotal = (result, type) =>{
    let total = 0
    result?.map((item)=>(
        total += item[type]
    ))
    return total
}