import followupService from "../services/followupService";
import { useMutation } from "react-query";

const useFollowupPending = (onData) => {
  return useMutation(followupService.getFollowupPending, {
    onSuccess: (data) => onData(data),
  })
}

const useLeadSinceLastFollowup = (onData) => {
  return useMutation(followupService.leadSinceLastFollowup, {
    onSuccess: (data) => onData(data),
  })
}

const useFollowupVisaTypeChange = (onData) => {
  return useMutation(followupService.followupVisaTypeChange, {
    onSuccess: (data) => onData(data),
  })
}

const followupQueries = {
    useFollowupPending,
    useLeadSinceLastFollowup,
    useFollowupVisaTypeChange
}

export default followupQueries