import axios from "axios";
import {
  onRequest,
  onRequestError,
  onResponse,
  onResponseError,
} from "./api-interceptors";

// export const BASE_URL = "http://localhost:5000";
export const BASE_URL = "https://api-geebee.solminds.in";
// export const BASE_URL = "https://api.themagweb.com";

const defaultOptions = {
  baseURL: `${BASE_URL}`,
  headers: {
    "Content-Type": "application/json",
  },
};
const instance = axios.create(defaultOptions);
instance.interceptors.request.use(
  (config) => onRequest(config),
  (error) => onRequestError(error)
);
instance.interceptors.response.use(
  (response) => onResponse(response),
  (error) => onResponseError(error)
);
export default instance;
