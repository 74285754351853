import { Modal } from '@nextui-org/react'
import React, { Fragment, useState } from 'react'
import ModalHeaderText from '../Common/ModalHeaderText'
import SelectSearch from '../Common/SelectSearch'
import InputSearch from '../Common/InputSearch'
import callCenterQueries from '../../queries/callCenterQueries'
import toast from 'react-hot-toast'
import { useApi } from '../Common/ApiProvider'
import moment, { min } from 'moment'

const CreateSmartLog = () => {

    const [ open, setOpen ] = useState(false)

    const [ calltype, setCalltype ] = useState('')
    const [ calltime, setCalltime ] = useState('')
    const [ applicant_no, setApplicant_no ] = useState('')
    const [ hour, setHour ] = useState(0)
    const [ minute, setMinute ] = useState(0)
    const [ second, setSecond ] = useState(1)


    const {setApiDataAndNotify} = useApi()

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const inputFields = [
        {
            label: "Call Type",
            size: 12,            
            state: calltype,
            setState: setCalltype,
            input: SelectSearch,
            value: [{id: "Incoming", name: "Incoming"}, {id: "Outgoing", name: "Outgoing"}],
            name: 'name'
        },
        {
            label: 'Call Start',
            name: 'calltime',
            type: 'datetime-local',
            input: InputSearch,
            size: 12,            
            state: calltime,
            setState: setCalltime,
            max: moment().format('YYYY-MM-DDTHH:mm:ss')
        },
        {
            label: 'Hour',
            size: 4,
            state: hour,
            setState: setHour,
            input: SelectSearch,
            hideBlankValue: true,
            value: Array.from({ length: 24 }, (_, i) => ({ id: i, name: `${i} Hour` })),
            name: 'name',
        },
        {
            label: 'Minute',
            size: 4,
            state: minute,
            setState: setMinute,
            input: SelectSearch,
            hideBlankValue: true,
            value: Array.from({ length: 60 }, (_, i) => ({ id: i, name: `${i} Minute` })),
            name: 'name'
        },
        {
            label: 'Second',
            size: 4,
            state: second,
            setState: setSecond,
            input: SelectSearch,
            hideBlankValue: true,
            value: Array.from({ length: 60 }, (_, i) => ({ id: i, name: `${i} Second` })),
            name: 'name',
        },
        {
            label: 'Mobile Number',
            name: 'applicant_no',
            type: 'text',
            required: true,
            size: 12,
            input: InputSearch,
            state: applicant_no,
            setState: setApplicant_no
        }
    ]

    const handleSubmit = () => {
        if(calltime === '' || applicant_no === '' || calltype === '' || hour === '' || minute === '' || second === ''){
            toast.error('All fields are required')
            return
        }
        create.mutateAsync({
            calltype,
            calltime,
            applicant_no,
            hour: Number(hour),
            minute: Number(minute),
            second: Number(second)
        })
    }

    const create = callCenterQueries.useCreateSmartLog(
        (res) => {
            if(res?.success){
                handleClose()
                handleReset()
                toast.success('Successfully Created')
                setApiDataAndNotify(new Date())
            }
        }
    )

    const handleReset = () => {
        setCalltype('')
        setCalltime('')
        setHour(1)
        setMinute(0)
        setSecond(1)
        setApplicant_no('')
    }



  return (
    <Fragment>
        <button className='custom-color px-6 py-2 text-[14px] font-medium rounded-md text-white' onClick={handleOpen}>Whatsapp Call Create</button>
        <Modal
        open={open}
        onClose={handleClose}
        width='600px'
        closeButton
        animationDuration={1000}
        overlayId="modal-overlay"
        >
            <Modal.Header>
                <ModalHeaderText label="Whatsapp Call Create" />
            </Modal.Header>
            <Modal.Body>

                <div className="grid grid-cols-12 max-[1389px]:grid-cols-10 max-[1163px]:grid-cols-8 max-[929px]:grid-cols-6 max-[585px]:grid-cols-4 max-[445px]:grid-cols-2 gap-x-6 gap-y-5 !mb-5">
                    {
                        inputFields?.map((item, index)=> {
                            return(
                            <div key={index} className={`col-span-${item?.size} flex flex-col gap-2`}>
                                <item.input item={item} />
                            </div>
                        )})
                    }
                </div>

                <div className='flex justify-end'>
                    <button onClick={handleSubmit} className='custom-color px-6 py-2 text-[14px] font-medium rounded-md text-white'>Submit</button>
                </div>

            </Modal.Body>
            <Modal.Footer>
                
            </Modal.Footer>
        </Modal>
    </Fragment>
  )
}

export default CreateSmartLog
