/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import Swiper from 'swiper';
import 'swiper/css';
import $ from 'jquery'
import authQueries from '../../queries/authQueries';
import { useNavigate } from 'react-router-dom';
import LoaderCommon from '../Common/LoaderCommon';
import CountUp from 'react-countup';
import { IconCalendarEvent, IconCalendarMinus, IconCalendarPlus, IconCheck, IconChecks, IconCircleCheck, IconCoinPound, IconDiscountCheck, IconHourglass, IconMoneybag, IconNote, IconNotes, IconPlaneDeparture, IconProgressCheck, IconRefreshAlert, IconSquare, IconUsersGroup, IconX } from '@tabler/icons-react';
import FollowupToday from '../Common/FollowupToday';
import FollowupPending from '../Common/FollowupPending';
import { useAdvancedSearch } from '../../store/advancedSearchStore';
import { userStore } from '../../store/userStore';
import RouteConstants from '../../Constants/RouteConstans';
import { CheckCircle, Vote } from 'lucide-react';

const ManagerDashboard = () => {

	useEffect(()=> {
		managerDashboardCount.mutateAsync()
	},[])

	const [result, setResult] = useState([])
	const [loader, setLoader] = useState(true);

	const setActiveTab = userStore((state)=> state.setActiveTab)
	const setStatus = useAdvancedSearch((state) => state.setStatus);
	const setRegistered = useAdvancedSearch((state) => state.setRegistered);
	const setFeepaid = useAdvancedSearch((state) => state.setFeepaid);
	const setFinancialYear = useAdvancedSearch((state) => state.setFinancialYear);
	const setFundReady = useAdvancedSearch((state) => state.setFundReady);
	const setIncluded = useAdvancedSearch((state) => state.setIncluded);
    const resetAll = useAdvancedSearch((state) => state.resetAll);
	
	const navigate = useNavigate()

	const managerDashboardCount = authQueries.useManagerDashboardMutation(
		(res) => {
			setResult(res)
			setLoader(false)
		}
	)

	const slideArr = [
		{
			color: '#98ABEE',
			title: 'Total Active Clients',
			value: "TOTAL",
			icon: <Vote color='#98ABEE' size="20" /> ,
			count: result?.activeClient,
		},
		{
			color: '#FC6736',
			title: 'Total Active Registered',
			value: "REG",
			icon: <CheckCircle color='#FC6736' size="20" />,
			count: result?.registered,
		},
		{
			color: '#85586F',
			title: 'Registered Clients from Previous Financial Year(s)',
			value: "PREVREG",
			icon: <IconCalendarMinus color='#85586F' size="20" />,
			count: result?.registeredPrevious,
		},
		{
			color: '#0D9276',
			title: 'Registered Clients from Current Financial Year',
			value: "CURRREG",
			icon: <IconCalendarEvent color='#0D9276' size="20" />,
			count: result?.registeredCurrent,
		},
		{
			color: '#637A9F',
			title: 'Registered Clients from Future Financial Year',
			value: "FUTREG",
			icon: <IconCalendarPlus color='#637A9F' size="20" />,
			count: result?.registeredFuture,
		},
	];

	const slideArr2 = [
		{
			color: '#D63484',
			title: 'Ready to mark registered',
			value: "READY",
			icon: <IconCircleCheck color='#D63484' size="20" /> ,
			count: result?.readyToMarkRegistered,
		},
		{
			color: '#365486',
			title: 'Awaiting program Confirmation',
			value: "PROCONFIRM",
			icon: <IconHourglass color='#365486' size="20" />,
			count: result?.programConfirmationNeeded,
		},
		{
			color: '#76453B',
			title: 'Full Docs no Application Moved',
			value: "FULLDOC",
			icon: <IconDiscountCheck color='#76453B' size="20" />,
			count: result?.fullDocsNoAppMoved,
			included: result?.fullDocIds
		},
		{
			color: '#DC84F3',
			title: 'Total Fee  paid  for current Financial year',
			value: "FEE",
			icon: <IconMoneybag color='#DC84F3' size="20" />,
			count: result?.feePaidCurrent,
		},
		{
			color: '#7ED7C1',
			title: 'Fund Ready Clients',
			value: "FUND",
			icon: <IconCoinPound color='#7ED7C1' size="20" />,
			count: result?.fundready,
		}
	];
	

	const slider = () => {

		new Swiper(".geebee-carousel.followup-pending", {
			slidesPerView: 5,
			spaceBetween: 10,
			speed: 700,
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
			},
			breakpoints: {
				"1": {
					slidesPerView: 1,
					spaceBetween: 10,
                },
				'480': {
					slidesPerView: 2,
					spaceBetween: 10,
				},
				'640': {
					slidesPerView: 3,
					spaceBetween: 10,
				},
				'920': {
					slidesPerView: 4,
					spaceBetween: 10,
				},
				'1200': {
					slidesPerView: 5,
					spaceBetween: 10,
				},
			},
			navigation: {
				nextEl: ".swiper-button-next.carousel-next",
				prevEl: ".swiper-button-prev.carousel-prev",
			},
		});

	}

	useEffect(() => {
		slider();
		$('html,body').animate({ scrollTop: 0 }, 'fast');
	}, [loader]);

	const handleNavigate =(item)=>{
		resetAll()
		if(item?.value === "REG"){
			setRegistered("YES")
		}
		if(item?.value === "READY"){
			setStatus(6)
		}
		if(item?.value === "PROCONFIRM"){
			setStatus(5)
		}
		if(item?.value === "FEE"){
			setFeepaid(true)
			setFinancialYear("CURRENT")
		}
		if(item?.value === "PREVREG"){
			setRegistered("YES")
			setFinancialYear("PREVIOUS")
		}
		if(item?.value === "CURRREG"){
			setRegistered("YES")
			setFinancialYear("CURRENT")
		}
		if(item?.value === "FUTREG"){
			setRegistered("YES")
			setFinancialYear("FUTURE")
		}
		if(item?.value === "FULLDOC"){
			setIncluded(item?.included)
		}
		if(item?.value === "FUND"){
			setFundReady(true)
		}
		setActiveTab(RouteConstants.MARKREGISTRATIONMYCLIENTS)
		navigate(RouteConstants.MARKREGISTRATIONMYCLIENTS)

	}


	return (
	<div>

		{
			loader?
			<div style={{display:'flex', justifyContent:'center', flexDirection:'column', marginTop:'10%'}}>
				<LoaderCommon />
			</div>:
			<div>
				<div className="carousel-section mb-4">
					<div className="row">
						<div className="col-md-12">
							<div className="swiper-container followup-pending geebee-carousel">
								<div className="swiper-wrapper">
									{slideArr.map((item, index) => (
										<button
										disabled={item?.count < 1}
										onClick={()=>handleNavigate(item)}
										key={index} 
										className={`swiper-slide h-32 rounded-2xl text-white cursor-pointer`} 
										style={{background:item.color}}>
											<div className='flex flex-col p-3 justify-between h-full'>
												<div className='flex justify-between'>
													<div className='bg-white rounded-full flex justify-center items-center w-10 h-10 color-red-500'>{item.icon}</div>
													<div className='text-4xl'>
														<CountUp end={item?.count} duration={1} />
													</div>
												</div>
												<div>
													<div className='text-[14px] text-left'>{item.title}</div>
												</div>
											</div>
										</button>
									))}
								</div>
							</div>
						</div>
						<div className="col-md-12 mt-4">
							<div className="swiper-container followup-pending geebee-carousel">
								<div className="swiper-wrapper">
									{slideArr2.map((item, index) => (
										<button
										disabled={item?.count < 1}
										onClick={()=>handleNavigate(item)}
										key={index} 
										className={`swiper-slide h-32 rounded-2xl text-white cursor-pointer`} 
										style={{background:item.color}}>
											<div className='flex flex-col p-3 justify-between h-full'>
												<div className='flex justify-between'>
													<div className='bg-white rounded-full flex justify-center items-center w-10 h-10 color-red-500'>{item.icon}</div>
													<div className='text-4xl'>
														<CountUp end={item?.count} duration={1} />
													</div>
												</div>
												<div>
													<div className='text-[14px] text-left'>{item.title}</div>
												</div>
											</div>
										</button>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>

				<FollowupToday />
				<FollowupPending />
			</div>
		}


	</div>
	)
}

export default ManagerDashboard