import React, { Fragment, useEffect, useState } from 'react'
import callCenterQueries from '../../queries/callCenterQueries'
import moment from 'moment'
import LoaderCommon from '../Common/LoaderCommon'
import SelectSearch from '../Common/SelectSearch'
import { Empty } from 'antd'
import InputSearch from '../Common/InputSearch'
import { Link } from 'react-router-dom'
import { GanttChartSquare } from 'lucide-react'
import PaginationComponent from '../Common/PaginationComponent'
import { usePaginationStore } from '../../store/userStore'
import { callLogUrl } from '../../Data/CallLogData'
import durationFind from '../Common/DurationFinder'
import CreateSmartLog from './CreateSmartLog'
import { useApi } from '../Common/ApiProvider'


const SmartLogListing = () => {

  const today = moment().format('YYYY-MM-DD')

  const [result, setResult] = useState([])
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('calltime')
  const [calltype, setCalltype] = useState('All')
  const [callstatus, setCallStatus] = useState('All')
  const [from, setFrom] = useState(today)
  const [to, setTo] = useState(today)
  const [loader, setLoader] = useState(true)

  const currentPage = usePaginationStore((state) => state.currentPage);
  const setCurrentPage = usePaginationStore((state) => state.setCurrentPage);

  const { apiData } = useApi()

  const callCenter = callCenterQueries.useSmartLogListing(
    (res)=>{
      setLoader(false)
      if(res?.success){
        setResult(res)
      }
    }
  )

  useEffect(() => {
    callCenter.mutateAsync({
      order: order,
      orderBy: orderBy,
      calltype,
      callstatus,
      from,
      to,
      page: currentPage,
      size: 50
    })
  }, [order, orderBy, calltype, callstatus, from, to, currentPage, apiData]);

  useEffect(() => {
    setCurrentPage(0)
  }, [order, orderBy, calltype, callstatus, from, to, apiData]);

  const inputFields = [
    {
      label: "Call Type",
      size: 2,            
      state: calltype,
      setState: setCalltype,
      input: SelectSearch,
      value: [{id: "Incoming", name: "Incoming"}, {id: "Outgoing", name: "Outgoing"}],
      name: 'name'
    },
    {
      label: "Call Status",
      size: 2,            
      state: callstatus,
      setState: setCallStatus,
      input: SelectSearch,
      value: [{id: "Connected", name: "Connected"}, {id: "Missed Call", name: "Missed Call"}, {id: "Call Failed", name: "Call Failed"}],
      name: 'name'
    },
    {
      label: "From Date",
      size: 2,            
      state: from,
      setState: setFrom,
      type: "date",
      input: InputSearch,
      name: 'name'
    },
    {
      label: "To Date",
      size: 2,            
      state: to,
      setState: setTo,
      type: "date",
      input: InputSearch,
      name: 'name'
    },
  ]

  return (
    <div>

      <div className="breadcrumb-and-otherbuttons border-b border-[#c7c8d3] mb-2">
          <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Smart Log</li>
              </ol>
          </nav>
          <div className='mb-2'>
            <CreateSmartLog />
          </div>	
      </div>

      <div className="flex items-center gap-[4px] mb-4">
          <i className="followup-icon">
              <GanttChartSquare size="25" className='text-[#7D6FAB]' />
          </i>
          <div style={{display:'flex', flexDirection:'column'}}>
              <div style={{display:'flex', alignItems:'center', gap:'10px'}}>
                  <span className="text-[16px] font-medium">Smart Log</span>	
              </div>
          </div>
      </div>

      <div className='bg-white p-10 overflow-auto'>
        <div className="grid grid-cols-12 max-[1389px]:grid-cols-10 max-[1163px]:grid-cols-8 max-[929px]:grid-cols-6 max-[585px]:grid-cols-4 max-[445px]:grid-cols-2 gap-x-6 gap-y-5 !mb-5">
            {
                inputFields?.map((item, index)=> {
                    return(
                    <div key={index} className={`col-span-${item?.size} flex flex-col gap-2`}>
                        <item.input item={item} />
                    </div>
                )})
            }
        </div> 

        {
          loader ?
            <LoaderCommon />
          :
          result?.data?.items?.length > 0 ?
          <Fragment>
            <table className='table-dotted w-full'>
              <thead>
                  <tr className="table-row call-log">
                      <th className='text-center'>#</th>
                      <th className='text-left'>Applicant Number</th>
                      <th className='text-left'>Refference ID</th>
                      <th>
                        {
                          order === 'asc' && orderBy === 'callduration' ? (
                            <button onClick={() => setOrder('desc')} className='flex w-full justify-center gap-2'>Call Duration<span>🔽</span></button>
                          ) :
                          order === 'desc' && orderBy === 'callduration' ? (
                            <button onClick={() => setOrder('asc')} className='flex w-full justify-center gap-2'>Call Duration<span>🔼</span></button>
                          ) : (
                            <button
                            onClick={() => {
                              setOrderBy('callduration')
                              setOrder('desc')
                            }}
                            >Call Duration</button>
                          )
                        }
                      </th>
                      <th>Call Status</th>
                      <th>Call Type</th>
                      <th>
                        {
                          order === 'asc' && orderBy === 'calltime' ? (
                            <button onClick={() => setOrder('desc')} className='flex w-full justify-center gap-2'>Call Time<span>🔽</span></button>
                          ) : 
                          order === 'desc' && orderBy === 'calltime' ? (
                            <button onClick={() => setOrder('asc')} className='flex w-full justify-center gap-2'>Call Time<span>🔼</span></button>
                          ) :
                          (
                            <button
                            onClick={() => {
                              setOrderBy('calltime')
                              setOrder('desc')
                            }}
                            >Call Time</button>
                          )
                        }
                      </th>
                      <th>
                        Download Recording
                      </th>
                  </tr>
              </thead>
              <tbody>
                  {result?.data?.items?.map((item, index) => {
                      return(
                          <tr key={index} className="table-row call-log">
                              <td className='text-center'>{(index + 1) + (result.data?.currentPage * 50)}</td>
                              <td className='text-left'>{item?.applicant_no}</td>
                              <td className='text-left'>{item.callrefid ? item.callrefid : 'Whatsapp call'}</td>
                              <td>{durationFind(item?.callduration)}</td>
                              <td>{item?.callstatus}</td>
                              <td>{item?.calltype}</td>
                              <td>
                                {moment(item?.calltime)
                                  .utcOffset("+05:30")
                                  .utc()
                                  .format("DD-MM-YYYY hh:mm:ss A")}
                              </td>
                              <td>
                                  {
                                    item?.callrefid ?
                                    <a className="text-[blue]" href={`${callLogUrl}/WebAPI.xbc?streamcallwaveeasy()&callrefid=${item.callrefid}`} target="_blank">Click here to download the recording</a>
                                    :
                                    <span>Not Available</span>
                                  }                        
                              </td>
                          </tr>
                      )}
                  )}
              </tbody>
            </table>
            <PaginationComponent result={result} currentPage={currentPage} setCurrentPage={setCurrentPage} />
          </Fragment>
          :
          <div className='mt-24 mb-24'>
            <Empty
            description={<div className="text-sm text-blue-700" style={{fontFamily: "var(--primary-font)"}}>No data found!</div>}
            />
          </div>
        }
      </div>

    </div>
  )
}

export default SmartLogListing
