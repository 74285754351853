import { Modal, Textarea } from '@nextui-org/react'
import { decode } from 'html-entities'
import moment from 'moment'
import React, { Fragment, useState } from 'react'
import authQueries from '../../queries/authQueries'
import { yupResolver } from '@hookform/resolvers/yup'
import { generateValidationSchema } from './validationSchema'
import { message } from 'antd'
import { set, useForm } from 'react-hook-form'
import { useApi } from './ApiProvider'
import toast from 'react-hot-toast'

const ApplicationStatusCreateModal = ({status, item, application}) => {

    const [open, setOpen] = useState(false)
    const [selectStatus, setSelectStatus] = useState(null)

    const inputFields = [
        {
            name: "status",
            required: true,
            label: "Application Status"
        },
        {
            name: "notes",
            required: true,
            label: "Comment",
            max: 256
        },
        {
            name: "due_date",
            required: (Number(selectStatus) === 5 || Number(selectStatus) === 6) ? true : false,
            label: "Due Date"
        }
    ]

    const { setApiDataAndNotify } = useApi()

    const handleOpen =()=>{
        setOpen(true)
    }

    const handleClose =()=>{
        setOpen(false)
    }

    const {handleSubmit, register, formState: { errors }, reset, setValue} = useForm({
        resolver: yupResolver(generateValidationSchema(inputFields))
    });

    const onSubmit = (data) => {
        let dataValue = {
            ...data,
            appid: item?.applicant_id,
            intid: item?.id,
            applicant: `${application?.applicant_name}${application?.middlename ? ` ${application?.middlename}` : ""}${application?.lastname ? ` ${application?.lastname}` : ""}`,
            counselor: application?.assignUser?.counselor,
            program: item?.program?.name,
            college: item?.college?.name,
            visatype: application?.visatype?.description
        }
        admissionCreate.mutateAsync(dataValue)
    }

    const admissionCreate = authQueries.useAdmissionRegisterCreate(
        (response) => {
            handleClose()
            setApiDataAndNotify(response)
            toast.success('Application Created Successfully');
            reset()
        }
    )

    const handleChangeStatus = (data) => {
        const statusId = Number(data)
        if(statusId === 5 || statusId === 6){
            setSelectStatus(statusId)
            setValue('due_date', "")
        }else{
            setSelectStatus(null)
            setValue('due_date', "")
        }
    }

  return (
    <Fragment>
        <button onClick={handleOpen} className='btn-customized'>Application Status</button>
        <Modal
        open={open}
        closeButton
        onClose={handleClose}
        className="followup-modal"
        centered
        blur
        animationDuration={1000}
        overlayId="modal-overlay"
        width='600px'
        scroll
        >
            <Modal.Header>
                <h5 className="modal-title">Change Application Status</h5>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='flex flex-col gap-3'>
                        <div className="flex flex-col gap-1">
                            <div className='text-[12px] h-4 font-[500]'>Status</div>
                            <select
                            {...register('status', {
                                onChange: (e) => handleChangeStatus(e.target.value)
                            })}
                            name="status" 
                            className="bg-white border border-solid border-gray-300 rounded-md w-full h-[44px] pl-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0" 
                            >
                            <option value="">Select Status</option>
                            {
                                status?.map((item, index) => (
                                <option value={item?.id} key={index}>{decode(item?.name)}</option>
                                ))
                            }
                            </select>
                            <div style={{color:'#f00', fontSize:"13px"}}>{errors?.status?.message}</div>
                        </div>
                        <div className="flex flex-col gap-1">
                            <div className='text-[12px] h-4 font-[500]'>Comment</div>
                            <textarea
                            className="bg-white border border-solid border-gray-300 rounded-md w-full h-10 p-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0 min-h-[100px]" 
                            {...register('notes')}
                            name="notes" 
                            rows="5"
                            ></textarea>
                            <div style={{color:'#f00', fontSize:"13px"}}>{errors?.notes?.message}</div>
                        </div>

                        {
                            (Number(selectStatus) === 5 || Number(selectStatus) === 6) &&
                            <div className="flex flex-col gap-1">
                                <div className='text-[12px] h-4 font-[500]'>Comment</div>
                                <input
                                className="bg-white border border-solid border-gray-300 rounded-md w-full h-[44px] pl-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0" 
                                {...register('due_date')}
                                name="due_date" 
                                type="date"
                                min={moment().format('YYYY-MM-DD')}
                                />
                                <div style={{color:'#f00', fontSize:"13px"}}>{errors?.due_date?.message}</div>
                            </div>
                        }
                        <div>
                            <button type="submit" className="btn-customized-large">Change Status</button>
                        </div>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    </Fragment>
  )
}

export default ApplicationStatusCreateModal