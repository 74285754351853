import apiRequest from '../apis/api-request';
import { userStore } from '../store/userStore';

const accessToken = userStore.getState().user.accessToken

const reportService = {
   
    collegeMarketingList: async (data) => {

        const page = data?.page ? data?.page : 0
        const size = data?.size ? data?.size : 25

        return apiRequest({
            method: "POST",
            url: `/api/ceo/report/college/marketing?size=${size}&page=${page}`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            },
        });
    },

    distributionReport: async (data) => {
        return apiRequest({
            method: "POST",
            url: `/api/ceo/report/lead/distribution/branch`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            },
        });
    },

    distributionReportCounselorWise: async (data) => {
        return apiRequest({
            method: "POST",
            url: `/api/ceo/report/lead/distribution/counselor`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            },
        });
    },

    distributionReportCounselorWiseApplicants: async (data) => {
        return apiRequest({
            method: "POST",
            url: `/api/ceo/report/lead/distribution/counselor/applicants`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            },
        });
    },

    distributionReportDropdown: async (data) => {
        return apiRequest({
            method: "GET",
            url: `/api/common/report/distribution/dropdown`,
            headers: {
                Authorization: "Bearer " + accessToken
            },
        });
    },

    admissionStaffPerformanceReport: async (data) => {
        return apiRequest({
            method: "POST",
            url: `/api/ceo/report/admission/performance`,
            data,
            headers: {
                Authorization: "Bearer " + accessToken
            },
        });
    },

    admissionStaffPerformanceReportDropdown: async () => {
        return apiRequest({
            method: "GET",
            url: `/api/common/report/admission/performance`,
            headers: {
                Authorization: "Bearer " + accessToken
            },
        });
    },

    applicationManagementAndStatusReport: async (data) => {
        return apiRequest({
            method: "POST",
            url: `/api/ceo/report/application/management`,
            data,
            headers: {
                Authorization: "Bearer " + accessToken
            },
        });
    },

    callCenterDurationReport: async (data) => {
        return apiRequest({
            method: "POST",
            url: `/api/ceo/report/counselor/callcenter`,
            data,
            headers: {
                Authorization: "Bearer " + accessToken
            },
        });
    },

    callCenterDurationDetailsReport: async (data) => {
        return apiRequest({
            method: "POST",
            url: `/api/ceo/report/counselor/callcenter_details`,
            data,
            headers: {
                Authorization: "Bearer " + accessToken
            },
        });
    }

}

export default reportService