import moment from "moment";
import create from "zustand";

export const useAdvancedSearch = create((set) => {
    const initialState = {
        name: "",
        email: "",
        mobile: "",
        visa: "",
        status: "",
        intake: "",
        office: "",
        district: "",
        college: "",
        reffered: "",
        secondary_reffered: "",
        appQualify: "",
        appStatus: "",
        registered: "ALL",
        activeOrAll: "ACTIVE",
        counselor: "",
        hot: true,
        warm: true,
        cold: true,
        feepaid: false,
        financialYear: "",
        fundReady: false,
        notproceeding: false,
        totalassigned: false,
        sopcompleted: false,
        prgmCoordinatorPending: false,
        visaStatusRemove: false,
        excludeOnhold: true,
        needSubmission: false,
        associated: "ALL",
        feePayment: "",
        included: [],
        excluded: [],
        prosStatus: [17,18,20],
        applicationStatus: [3],
        documentStaff: "",
        pgCoordinator: "",
        admissionStaff: "",
        feeCoordinator: "",
        visaStaff: "",
        sopStaff: "",
        spCoordinator: "",
        createdFrom: "",
        createdTo: moment().format("YYYY-MM-DD"),
        feeVendor: "",
        daysPendingForSubmission: "",
        nir: "",
        visaDataByName: "",
        visaDataByStatus: "YES",
        visaDataByDate: "",
        sort: "DESC",
        sortBy: "createdAt"
    }
    return{
      ...initialState,
      setName: (element) => set({ name: element }),
      setEmail: (element) => set({ email: element }),
      setMobile: (element) => set({ mobile: element }),
      setVisa: (element) => set({ visa: element }),
      setStatus: (element) => set({ status: element }),
      setIntake: (element) => set({ intake: element }),
      setOffice: (element) => set({ office: element }),
      setDistrict: (element) => set({ district: element }),
      setCollege: (element) => set({ college: element }),
      setReffered: (element) => set({ reffered: element }),
      setSecondaryReffered: (element) => set({ secondary_reffered: element }),
      setAppQualify: (element) => set({ appQualify: element }),
      setAppStatus: (element) => set({ appStatus: element }),
      setActiveOrAll: (element) => set({ activeOrAll: element }),
      setCounselor: (element) => set({ counselor: element }),
      setHot: (element) => set({ hot: element }),
      setWarm: (element) => set({ warm: element }),
      setCold: (element) => set({ cold: element }),
      setRegistered: (element) => set({ registered: element }),
      setFeepaid: (element) => set({ feepaid: element }),
      setFinancialYear: (element) => set({ financialYear: element }),
      setFundReady: (element) => set({ fundReady: element }),
      setNotProceeding: (element) => set({ notproceeding: element }),
      setTotalAssigned: (element) => set({ totalassigned: element }),
      setSopCompleted: (element) => set({ sopcompleted: element }),
      setFeePayment: (element) => set({ feePayment: element }),
      setPrgmCoordinatorPending: (element) => set({ prgmCoordinatorPending: element }),
      setVisaStatusRemove: (element) => set({ visaStatusRemove: element }),
      setExcludeOnHold: (element) => set({ excludeOnhold: element }),
      setNeedSubmission: (element) => set({ needSubmission: element }),
      setAssociated: (element) => set({ associated: element }),
      setIncluded: (element) => set({ included: element }),
      setExcluded: (element) => set({ excluded: element }),
      setProsStatus: (element) => set({ prosStatus: element }),
      setApplicationStatus: (element) => set({ applicationStatus: element }),
      setDocumentStaff: (element) => set({ documentStaff: element }),
      setPgCoordinator: (element) => set({ pgCoordinator: element }),
      setAdmissionStaff: (element) => set({ admissionStaff: element }),
      setFeeCoordinator: (element) => set({ feeCoordinator: element }),
      setVisaStaff: (element) => set({ visaStaff: element }),
      setSopStaff: (element) => set({ sopStaff: element }),
      setSpCoordinator: (element) => set({ spCoordinator: element }),
      setCreatedFrom: (element) => set({ createdFrom: element }),
      setCreatedTo: (element) => set({ createdTo: element }),
      setFeeVendor: (element) => set({ feeVendor: element }),
      setDaysPendingForSubmission: (element) => set({ daysPendingForSubmission: element }),
      setNir: (element) => set({ nir: element }),
      setVisaDataByName: (element) => set({ visaDataByName: element }),
      setVisaDataByStatus: (element) => set({ visaDataByStatus: element }),
      setVisaDataByDate: (element) => set({ visaDataByDate: element }),
      onlyHot: (element) => set({ hot: true, warm: false, cold: false, registered: element ? element : "NO" }),
      onlyWarm: (element) => set({ hot: false, warm: true, cold: false, registered: element ? element : "NO" }),
      onlyCold: (element) => set({ hot: false, warm: false, cold: true, registered: element ? element : "NO" }),
      resetAll: () => set(initialState),
      setSort: (element) => set({ sort: element }),
      setSortBy: (element) => set({ sortBy: element }),
    }
  });