import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'
import { useApi } from '../Common/ApiProvider';
import { useAdvancedSearch } from '../../store/advancedSearchStore';
import { useLoaderStore, usePaginationStore } from '../../store/userStore';
import authQueries from '../../queries/authQueries';
import { IconClearAll } from '@tabler/icons-react';
import AdvancedSearch from '../Common/AdvancedSearch';
import LoaderCommon from '../Common/LoaderCommon';
import LeadStatus from '../Common/LeadStatus';
import moment from 'moment';
import FollowUp from '../Common/FollowUp';
import VisaStaffVisaData from '../Common/VisaStaffVisaData';
import ApplicantViewButton from '../Common/ApplicantViewButton';
import DocumentUploadModal from '../Common/DocumentUploadModal';
import { Empty } from 'antd';
import PaginationComponent from '../Common/PaginationComponent';
import ProspectStatusList from '../Common/ProspectStatusList';
import { decode } from 'html-entities';
import ApplicationStatusModal from '../Common/ApplicationStatusModal';
import ApplicantEditButton from '../Common/ApplicantEditButton';

const VisaStaffMyClients = () => {

    const { apiData } = useApi();
    const [result, setResult] = useState([]);
    const [active, setActive] = useState({ index: null, status: false });

    const data = useAdvancedSearch((state) => state);

    const currentPage = usePaginationStore((state) => state.currentPage);
    const setCurrentPage = usePaginationStore((state) => state.setCurrentPage);
    const loader = useLoaderStore((state) => state.loader);
    const setLoader = useLoaderStore((state) => state.setLoader);
    const resetPage = usePaginationStore((state) => state.resetPage);
    const resetAll = useAdvancedSearch((state)=> state.resetAll)

    const listApplicants = authQueries.useApplicantAssignVisaStaffMutationList(
        (response) => {
            setLoader(false)
            setResult(response)
        }
    );

    useEffect(() => {
        setLoader(true)
    }, [data, currentPage]);

    useEffect(() => {
        listApplicants.mutateAsync({
            page: currentPage,
            size: 25,
            ...data,
        })
    }, [data, apiData, currentPage])

    const expantButton = (index) => {
        if (active.index === index) {
            setActive({
                index: null,
                status: false
            })
        } else {
            setActive({
                index: index,
                status: true
            })
        }
    }

    const resetFilters =()=>{
        resetAll()
        resetPage()
    }

    const counselorName =(item)=> {
        if(!item) return 'N/A'
		const name = item?.firstname +" "+ item?.lastname 
		return name
	}

  return (
    <Fragment>
        <div className="add_clients">
            <div className="row">
                <div className="col-md-6 appointments-hing-wrpr">
                    <i className="appointments-icon">
                        <img src={require('../../assets/images/appointments-today-icon.png')} width="" height="" alt="" />
                    </i>
                    <h2><span>My</span>Clients</h2>
                </div>
                <div className="col-md-12">
                    <hr className="progress-hr" />
                </div>
            </div>

            <div id="accordion" className="add_clients_accordion_form">
                <div className="card" id="appointments_wrpr">
                    <div className="card-header flex justify-between">
                        <a className="collapsed card-link" href="" style={{ pointerEvents: 'none' }}>
                            My Clients
                        </a>
                        <div>
                            <button className='text-[12px] flex gap-2 items-center' onClick={resetFilters}>
                                <IconClearAll size={17} />
                                Clear Filters
                            </button>
                        </div>
                    </div>
                    <div id="appointment_any" className="collapse show" data-parent="#accordion">
                        <div className="card-body">

                            <AdvancedSearch visaDataFilter={true} />

                            <div className="table-list-search-wrpr simple-search">
                                <div className="search-results-count-wrpr">
                                    {
                                    result?.data?.totalItems > 0 ?
                                    `Search Results ${result?.data?.currentPage * 25 + 1}-${result?.data?.currentPage * 25 + result?.data?.items?.length} of ${result?.data?.totalItems}` :
                                    `O results found`
                                    }
                                </div>

                                {
                                    loader ? 
                                    <LoaderCommon />:
                                    result?.data?.totalItems > 0 ?
                                    <div className="table-wrpr">
                                        <div className="table-section">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <table className="table prospect-table">
                                                        <thead>
                                                            <tr className='alt-colors'>
                                                                <th width="30">#</th>
                                                                <th>Profile Status</th>
                                                                <th>Office</th>
                                                                <th>Date Created</th>
                                                                <th>Applicant Name</th>
                                                                <th>Contact Number</th>
                                                                <th>Visa Type</th>
                                                                <th>Intake</th>
                                                                <th>Counselor</th>
                                                                <th>Reg. Date</th>
                                                                <th>Prospect Status</th>
                                                                <th>Referred By</th>
                                                                <th width='150'>&nbsp;</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="ui-sortable">
                                                            {result?.data?.items?.map((element, index) => {
                                                                const item = element?.application
                                                                return(
                                                                    <Fragment key={index}>
                                                                        <tr>
                                                                            <td>{(index + 1) + (result?.data?.currentPage * 25)}</td>
                                                                            <td>
                                                                                <LeadStatus item={item} />
                                                                            </td>
                                                                            <td>{item?.office?.name}</td>
                                                                            <td>{moment(item?.createdAt).format('DD-MM-YYYY')}</td>
                                                                            <td>
                                                                                <a style={{color:'blue', cursor:'pointer'}} onClick={()=>{expantButton(index)}}>
                                                                                    {item?.applicant_name} {item?.middlename} {item?.lastname}
                                                                                </a>
                                                                            </td>
                                                                            <td>
                                                                                <div className="email-mobile flex flex-col">
                                                                                    <div>{item.mobile_no}</div>
                                                                                    <div>{item.landline_no}</div>
                                                                                    <div>{item.whatsapp_no}</div>
                                                                                </div>
                                                                            </td>
                                                                            <td>{item?.visatype?.country?.icon} {item?.visatype?.description}</td>
                                                                            <td>{item?.intake?.intake}</td>
                                                                            <td>
                                                                                {counselorName(item?.assignUser?.CounselorAssign?.userdetail)}
                                                                            </td>
                                                                            <td>
                                                                                {item?.registered_date ? <span className="registered">{moment(item?.registered_date).format('DD-MM-YYYY')}</span> : '-'}
                                                                            </td>
                                                                            <td>
                                                                                <ProspectStatusList item={item} />
                                                                            </td>
                                                                            <td>{item?.refferal?.refferal}</td>	
                                                                            <td>
                                                                                <div className='grid grid-cols-12 gap-1 min-w-[170px]'>
                                                                                    <div className='col-span-12'>
                                                                                        <FollowUp item={item}/>
                                                                                    </div>
                                                                                    <div className='col-span-6'>
                                                                                        <VisaStaffVisaData item={item} />
                                                                                    </div>
                                                                                    <div className='col-span-3'>
                                                                                        <ApplicantViewButton item={item} />
                                                                                    </div>
                                                                                    <div className='col-span-3'>
                                                                                        <ApplicantEditButton item={item} />
                                                                                    </div>
                                                                                </div>

                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan="13" className={active.index === index && active.status ? "dropdown-table-wrpr active" : "dropdown-table-wrpr"}>
                                                                                <table className="table dropdown-table">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>Intake</th>
                                                                                            <th>Program Name</th>
                                                                                            <th>College / University</th>
                                                                                            <th>Campus</th>
                                                                                            <th>Program Link</th>
                                                                                            <th>Program Code</th>
                                                                                            <th>Application Status</th>
                                                                                            <th></th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {item?.intended_programs
                                                                                        ?.sort((a, b) => a.priority - b.priority)
                                                                                        ?.map((items, indexes) => (
                                                                                            <tr key={indexes}>
                                                                                                <td>{items?.intake?.intake}</td>
                                                                                                <td>{items?.program?.name}</td>
                                                                                                <td>{items?.college?.name}</td>
                                                                                                <td>{items?.campus? items?.campus?.name : 'Not Applicable' }</td>
                                                                                                <td><a href={items?.program?.programlink}>{items?.program?.programlink && 'Click Here'}</a></td>
                                                                                                <td>{items?.program?.program_code}</td>
                                                                                                <td>
                                                                                                    <ApplicationStatusModal item={items} />
                                                                                                </td>
                                                                                                <td className="action-icons action-links"  style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'flex-end'}}>
                                                                                                    <DocumentUploadModal item={items} />
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                    </Fragment>
                                                                )}
                                                            )}
                                                        </tbody>
                                                    </table>
                                                    <PaginationComponent result={result} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                                </div>
                                            </div>
                                        </div>
                                    </div> :
                                    <div className='mt-24 mb-24'>
                                        <Empty
                                        description={<div className="text-sm text-blue-700" style={{fontFamily: "var(--primary-font)"}}>Clients not found!</div>}
                                        />
                                    </div>
                                }

                            </div>

                        </div>
                    </div>
                </div>

            </div>

        </div>
    </Fragment>
  )
}

export default VisaStaffMyClients