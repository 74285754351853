import React, { Fragment, useEffect, useState } from 'react'
import InputSearch from './InputSearch'
import authQueries from '../../queries/authQueries';
import SelectSearch from './SelectSearch';
import RegisteredCheckSearch from './RegisteredCheckSearch';
import { userStore } from '../../store/userStore';
import { useAdvancedSearch } from '../../store/advancedSearchStore';
import { useForm } from 'react-hook-form';

const AdvancedSearchInactive = ({handleSearch}) => {

    const store = userStore()

    const { handleSubmit } = useForm()

    const [masterData, setMasterData] = useState({})

    const name = useAdvancedSearch((state) => state.name);
    const mobile = useAdvancedSearch((state) => state.mobile);
    const email = useAdvancedSearch((state) => state.email);
    const visa = useAdvancedSearch((state) => state.visa);
    const reffered = useAdvancedSearch((state) => state.reffered);
    const appQualify = useAdvancedSearch((state) => state.appQualify);
    const intake = useAdvancedSearch((state) => state.intake);
    const office = useAdvancedSearch((state) => state.office);
    const district = useAdvancedSearch((state) => state.district);
    const hot = useAdvancedSearch((state) => state.hot);
    const warm = useAdvancedSearch((state) => state.warm);
    const cold = useAdvancedSearch((state) => state.cold);
    const registered = useAdvancedSearch((state) => state.registered);
    const counselor = useAdvancedSearch((state) => state.counselor);
    const createdFrom = useAdvancedSearch((state) => state.createdFrom);
    const createdTo = useAdvancedSearch((state) => state.createdTo);
    const nir = useAdvancedSearch((state) => state.nir);

    const setName = useAdvancedSearch((state) => state.setName);
    const setMobile = useAdvancedSearch((state) => state.setMobile);
    const setEmail = useAdvancedSearch((state) => state.setEmail);
    const setVisa = useAdvancedSearch((state) => state.setVisa);
    const setReffered = useAdvancedSearch((state) => state.setReffered);
    const setAppQualify = useAdvancedSearch((state) => state.setAppQualify);
    const setIntake = useAdvancedSearch((state) => state.setIntake);
    const setOffice = useAdvancedSearch((state) => state.setOffice);
    const setDistrict = useAdvancedSearch((state) => state.setDistrict);
    const setHot = useAdvancedSearch((state) => state.setHot);
    const setWarm = useAdvancedSearch((state) => state.setWarm);
    const setCold = useAdvancedSearch((state) => state.setCold);
    const setRegistered = useAdvancedSearch((state) => state.setRegistered);
    const setCounselor = useAdvancedSearch((state) => state.setCounselor);
    const setCreatedFrom = useAdvancedSearch((state) => state.setCreatedFrom);
    const setCreatedTo = useAdvancedSearch((state) => state.setCreatedTo);
    const setNir = useAdvancedSearch((state) => state.setNir);

    const advancedMasterList = authQueries.useAdvancedSearchMutation(
        (response) => {
            setMasterData(response?.data)
        }
    );

    useEffect(() => {
        advancedMasterList.mutateAsync({visa, reffered, inactiveCounselor: true, allCounselor: true})
    }, [visa, reffered])

    const chipData = [
        {
            id: 1,
            label: "HOT",
            state: hot,
            setState: setHot,
        },
        {
            id: 2,
            label: "WARM",
            state: warm,
            setState: setWarm,
        },
        {
            id: 3,
            label: "COLD",
            state: cold,
            setState: setCold,
        }
    ]

    const findUser =(role, isHiddenNotAssigned)=>{
        let filterData = []
        if(!isHiddenNotAssigned){
            filterData.push({id:"NOTASSIGNED", name:"Not Assigned"})
        }
        const data = masterData?.user?.filter(user => user.roles.includes(role))
        data?.map((item) => {
            filterData.push({
                id: item.id,
                name: item.name
            })
        })
        return filterData
    }

    const inputSearchArry = [
        {
            label: "Applicant Name",
            size: 2,
            state: name,
            setState: setName,
            type: "text",
            input: InputSearch
        },
        {
            label: "Contact Number",
            size: 2,
            state: mobile,
            setState: setMobile,
            type: "number",
            input: InputSearch
        },
        {
            label: "Email",
            size: 2,
            state: email,
            setState: setEmail,
            type: "text",
            input: InputSearch
        },
        {
            label: "Visa Type",
            size: 2,            
            state: visa,
            setState: setVisa,
            input: SelectSearch,
            value:  masterData?.visatype,
            name: 'description',
        },
        {
            label: "Branch Office",
            size: 2,            
            state: office,
            setState: setOffice,
            input: SelectSearch,
            value: masterData?.office,
            name: 'name',
        },
        {
            label: "Registration Status",
            size: 2,            
            state: registered,
            setState: setRegistered,
            input: RegisteredCheckSearch,
        },
        {
            label: "Intake",
            size: 2,            
            state: intake,
            setState: setIntake,
            input: SelectSearch,
            value: masterData?.intake,
            name: 'intake'
        },
        {
            label: "District",
            size: 2,            
            state: district,
            setState: setDistrict,
            input: SelectSearch,
            value: masterData?.district,
            name: 'name',
        },
        {
            label: "Refferred By",
            size: 2,            
            state: reffered,
            setState: setReffered,
            input: SelectSearch,
            value: masterData?.refferal,
            name: 'refferal',
        },
        {
            label: "Applicant Qualification",
            size: 2,            
            state: appQualify,
            setState: setAppQualify,
            input: SelectSearch,
            value: masterData?.qualification,
            name: 'name',
        },
        {
            label: "Not ineterested reason",
            size: 2,            
            state: nir,
            setState: setNir,
            input: SelectSearch,
            value: masterData?.nir,
            name: 'name',
        },
        {
            label: "Counselor",
            size: 2,            
            state: counselor,
            setState: setCounselor,
            input: SelectSearch,
            value: findUser(3),
            hidden: store?.user?.role === "COUNSELOR" ? true : false,
            name: 'name',
            simple: false,
        },
    ]


    const dateFilterArry = [
        {
            label: "From Date",
            size: 2,            
            state: createdFrom,
            setState: setCreatedFrom,
            type: "date",
            input: InputSearch,
            name: 'name',
            simple: false,
        },
        {
            label: "To Date",
            size: 2,            
            state: createdTo,
            setState: setCreatedTo,
            type: "date",
            input: InputSearch,
            name: 'name',
            simple: false,
        },
    ]


  return (
    <form onSubmit={handleSubmit(handleSearch)}>

        <div className="grid grid-cols-12 max-[1389px]:grid-cols-10 max-[1163px]:grid-cols-8 max-[929px]:grid-cols-6 max-[585px]:grid-cols-4 max-[445px]:grid-cols-2 gap-x-6 gap-y-5 !mb-5">
            {
                inputSearchArry?.map((item, index)=> {
                    return(
                    <Fragment key={index}>
                        <div key={index} className={`col-span-${item?.size} flex flex-col gap-2`}>
                            <item.input item={item} />
                        </div>
                    </Fragment>
                )})
            }
        </div>        
        
        <div className="grid grid-cols-12 max-[1389px]:grid-cols-10 max-[1163px]:grid-cols-8 max-[929px]:grid-cols-6 max-[585px]:grid-cols-4 max-[445px]:grid-cols-2 gap-x-6 gap-y-5 !mb-5">
            {
                dateFilterArry?.map((item, index)=> {
                    return(
                    <Fragment key={index}>
                        <div key={index} className={`col-span-${item?.size} flex flex-col gap-2`}>
                            <item.input item={item} />
                        </div>
                    </Fragment>
                )})
            }
            <div className={`col-span-1 flex flex-col gap-2`}>
                <div className="text-[12px] h-4 font-[500]"></div>
                <button type="submit" className="btn-customized-large h-[44px] text-[14px]">Search</button>
            </div>
        </div>
        
        <div className='grid-cols-2 !mb-5'>
            <div className="col-span-2 max-[585px]:col-span-4 max-[445px]:col-span-2 justify-center flex flex-col gap-2">
                <div className="text-[12px] h-4 font-[500]">Profile Status</div>
                <div className="flex gap-2 items-center flex-wrap">
                    {
                        chipData.map((item, index)=> (
                            <div
                            key={index} 
                            onClick={()=>{item?.state ? item?.setState(false):item?.setState(true)}} 
                            className={`${item.state ? "bg-[#28a745]" : "bg-[#ff4d4f]"} flex items-center h-[30px] rounded-full cursor-pointer justify-start`}
                            >
                                <div className="text-[24px] flex pl-[4px]">
                                    {
                                        item.state ?
                                        <i className='icon-cancel1 text-white'/>:
                                        <i className='icon-plus1 text-white' />
                                    }
                                </div>
                                <div className="text-[10px] text-white font-semibold pl-2 pr-3">
                                    {item.label} Client
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
      
    </form>
  )
}

export default AdvancedSearchInactive
