import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast';
import authQueries from '../../queries/authQueries'
import followupQueries from '../../queries/followupQueries';
import { useApi } from './ApiProvider';

const VisaTypeChangeDropdown = ({item, close, setApplicant}) => {

    const [visa, setVisa] = useState(null);

    const { setApiDataAndNotify } = useApi()

    const visatypes = authQueries.useVisaTypeMutationList(
        (res)=>{
        }
    )

    useEffect(() => {
        visatypes.mutateAsync({size: 100000});
    }, []);

    const handleVisaChange = followupQueries.useFollowupVisaTypeChange(
        (res)=>{
            if(res?.success){
                toast.success('Visa type changed successfully');
                setVisa(null);
                setData(res);
                close()
                setApiDataAndNotify(new Date())
            }else{
                toast.error('Failed to change visa type');
            }

        }
    )

    const setData = (res) => {
        const data = {
            ...item,
            visa_type_id: res?.data?.visa_type_id,
            visatype: res?.data?.visatype
        }
        setApplicant(data)
    }

    const handleSubmit = () => {
        if(!visa){
            toast.error('Please select visa type');
            return;
        }

        const data = {
            field: 'visa_type_id',
            value: visa,
            id: item?.id
        }

        handleVisaChange.mutateAsync(data);

    }

  return (
    <div className="grid grid-cols-12 gap-2">
        <select
        onChange={(e)=>setVisa(e.target.value)}
        className="col-span-9 bg-white border border-solid border-gray-300 rounded-md h-[44px] pl-[10px] text-[12px] focus:border-none outline-none" 
        >
            <option value="">Select Visa Type</option>
            {
                visatypes?.data?.data?.totalItems > 0 && visatypes?.data?.data?.items?.map((item, index) => {
                    return (
                        <option key={index} value={item.id}>{item?.description}</option>
                    )
                })
            }
        </select>
        <button
        onClick={handleSubmit}
        className="col-span-3 custom-color border border-solid border-gray-300 rounded-md h-[44px] text-[12px] focus:border-none outline-none text-white"
        >
            Change
        </button>
    </div>
  )
}

export default VisaTypeChangeDropdown
