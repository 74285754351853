import apiRequest from '../apis/api-request';
import { userStore } from '../store/userStore';

const accessToken = userStore.getState().user.accessToken

const followupService = {
   
    getFollowupPending: async (data) => {
        return apiRequest({
            method: "POST",
            url: "/api/followupdashboard/common/allpending",
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            },
        });
    },

    leadSinceLastFollowup: async (data) => {
        return apiRequest({
            method: "POST",
            url: "/api/followupdashboard/last/followup",
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            },
        });
    },

    followupVisaTypeChange : async (data) => {
        return apiRequest({
            method: "POST",
            url: "/api/application/applicant/visatype/change",
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            },
        });
    },

}

export default followupService